import React from 'react';
import GatsbyImage, { FixedObject, FluidObject } from 'gatsby-image';
import styles from './Quote.module.scss';
import { Citation } from '../../types';

interface QuoteProps {
  children: string;
  authorid?: string;
  citation?: string;
  name?: string;
  title?: string;
  color: 'plum' | 'blue' | 'gold' | 'rust';
  link?: string;
  picture?: { childImageSharp: { fluid: FluidObject; fixed: FixedObject } };
  citations?: Citation[];
}

function createCite(
  title?: string,
  name?: string,
  link?: string,
  picture?: { childImageSharp: { fluid: FluidObject; fixed: FixedObject } },
) {
  const cite = (
    <>
      {picture ? (
        <div className={styles.citePhoto}>
          <GatsbyImage fluid={picture.childImageSharp.fluid} />
        </div>
      ) : null}
      <div className={styles.citeAuthor}>
        <div className={styles.authorName}>{name}</div>
        <div className={styles.authorTitle}>{title}</div>
      </div>
    </>
  );

  return link ? (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className={styles.citeContainer}
    >
      {cite}
    </a>
  ) : (
    <div className={styles.citeContainer}>{cite}</div>
  );
}

const Quote: React.FunctionComponent<QuoteProps> = ({
  children,
  authorid,
  citation,
  name,
  title,
  link,
  picture,
  color,
  citations,
}) => {
  const citeData = citations?.find((c) => c.id === authorid);

  let cite = null;

  if (citeData?.id) {
    cite = createCite(
      citeData.title,
      citeData.name,
      citeData.link,
      citeData.picture,
    );
  } else if (name != null || citation != null) {
    let authorName = name;
    let authorTitle = title;

    if (!title && citation) {
      [authorName] = citation.split(',');
      authorTitle = citation.split(',').slice(1).join(',');
    }

    cite = createCite(authorTitle, authorName, link, picture);
  }

  return (
    <blockquote className={styles[color || citeData?.quoteColor || 'plum']}>
      {children}
      {cite && (
        <footer>
          <cite>{cite}</cite>
        </footer>
      )}
    </blockquote>
  );
};

export default Quote;
